<template>
<base-page>
<titlu-pagina Titlu="Dashboard" :AdaugaVisible='false'></titlu-pagina>

    
   <!-- <el-col :span="6" v-for="m in masini_in_lucru" :key="'masina-'+m.Id">
        <el-card class="box-card car-box-card">
            <el-row :gutter='15'>
                <el-col :span='24'>
                    <p style='font-weight: bold'> {{m.Marca}} - {{m.Model}} - {{m.NrInmatriculare}}</p>
                </el-col>
                
                <el-col :span='24'>
                    <el-button type='primary' class='full-width' @click='arata_checklist(m)'>Check 360</el-button>
                </el-col>
                
                <el-col :span='24'>
                    <el-button class='full-width' v-if="m.IdPartener == '-1'" @click="arata_partener_dialog(m.Id)">Adauga partener</el-button>
                    <el-button class='full-width' type='success' v-if="m.IdPartener != '-1'"  @click="arata_partener_dialog(m.Id)"> Partener: {{m.NumePartener}} </el-button>
                </el-col>
                <el-col :span='24'>
                    <hr/>
                    <el-button type='danger' class='full-width' @click="sterge_masina(m)">Sterge masina</el-button>
                </el-col>
            </el-row>
        </el-card>
        
    </el-col> -->
   
   
    <!-- <el-card style='margin:5px 0px 5px 0px'>
    <div slot="header" class="clearfix">
    <strong>Dashboard</strong>
    </div>
    <div>
    <p>continut dashboard</p>
    </div>
    </el-card> -->
</base-page>

</template>

<script>
import settings from "../backend/LocalSettings";
import BasePage from "../pages/BasePage";
import TitluPagina from '../widgets/TitluPagina';

export default {
    name: "dashboard",
    extends: BasePage,
    components: {
        'base-page'   : BasePage,
        'titlu-pagina': TitluPagina
    },
    data () {
        return {
        }
    }, 
    methods: {
        async get_info(){                  
            this.refresh_info();
        },

        async refresh_info(){
            this.select_menu_item('dashboard');
        },

    },
    mounted(){
        this.get_info();
    }
};
</script>

<style lang="less" scoped>

.top50{
    margin-top: 20px;
}

</style>
